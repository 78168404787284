import { type UseMutateAsyncFunction, type UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { type CognitoUser } from 'amazon-cognito-identity-js';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { createAccount as createPsAccount } from '@/api/auth';
import { fetchApi, setAuthorizationHeader } from '@/api/client';
import { getUserNameFromToken, signIn as signInToCognito, signInWithAuthorizationCode } from '@/api/cognito';
import { type components } from '@/api/schemas';
import { Loading } from '@/components/Loading';

export type Scope = 'user' | 'initial';

type NullableGender = components['schemas']['NullableGender'];
type Gender = components['schemas']['Gender'];
type LegacySessionForPs = components['schemas']['LegacySessionForPs'];
type GetLegacySessionRequestForPs = components['schemas']['GetLegacySessionRequestForPs'];

export type PatientInfo = {
  type: 'self' | 'family' | 'sharing' | 'familySharing';
  name: string;
  kana: string;
  gender: NullableGender;
  birthDate: string;
  email?: string;
};

export interface CognitoIdentity {
  userId: string;
  providerName: string;
  providerType: string;
  issuer: string | null;
  primary: boolean;
  dateCreated: string;
}

export interface CognitoTokenData extends JwtPayload {
  at_hash: string;
  'cognito:groups': string[];
  email_verified: boolean;
  'cognito:username': string;
  origin_jti: string;
  identities: CognitoIdentity[];
  token_use: string;
  auth_time: number;
  email: string;
}

type ContextValue = {
  user?: CognitoTokenData;
  patients?: Record<string, PatientInfo>;
  mainPatientId: string;
  currentPatientId: string;
  setCurrentPatientId: (patient: string) => void;
  isLoading: boolean;
  scopes: Scope[];
  signInWithPassword: UseMutateFunction<
    {
      accessToken: string;
      idToken: string;
      refreshToken: string;
      user: CognitoUser | null;
    },
    Error,
    {
      username: string;
      password: string;
    },
    unknown
  >;
  isSignInWithPasswordPending: boolean;
  signInWithCode: (code: string) => Promise<
    | boolean
    | {
        accessToken: string;
        expiresIn: number;
        idToken: string;
        refreshToken: string;
        tokenType: string;
      }
  >;
  signInToCenterServer: UseMutateAsyncFunction<LegacySessionForPs, Error, GetLegacySessionRequestForPs, unknown>;
  signOut: () => boolean;
  createAccount: (params: { name: string; kana: string; birthDate: string; gender: Gender }) => Promise<string>;
  isSnsNeedRedirect: boolean;
  setIsSnsNeedRedirect: (enable: boolean) => void;
};

const DEFAULT_CONTEXT_VALUE: ContextValue = {
  user: undefined,
  patients: {},
  mainPatientId: '',
  currentPatientId: '',
  setCurrentPatientId: () => undefined,
  isLoading: true,
  scopes: [],
  signInWithPassword: () => false,
  isSignInWithPasswordPending: false,
  signInWithCode: async () => false,
  signInToCenterServer: async () => {
    throw new Error('Not implemented');
  },
  signOut: () => false,
  createAccount: async () => '',
  isSnsNeedRedirect: false,
  setIsSnsNeedRedirect: () => undefined,
};

const context = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export function AuthProvider(props: Props) {
  const { children } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const urlParams = {
    cognitoUserName: searchParams.get('cognitoUserName') || '',
    refreshToken: searchParams.get('refreshToken') || '',
    currentAccount: searchParams.get('currentAccount') || '',
  };

  const [user, setUser] = useState<CognitoTokenData | undefined>(undefined);

  // NOTE: For useQuery, don't call this function directly
  const { mutateAsync: signInToCenterServer } = useMutation({
    mutationFn: (params: GetLegacySessionRequestForPs) =>
      fetchApi({
        url: '/ps/v1/legacy/session',
        method: 'post',
        data: {
          ...params,
          targetAccountId: params?.targetAccountId || currentPatientId,
          deviceCategory: '20',
        },
      }).then((res) => res.data),
  });

  // Token save in memory
  const [tokenData, setTokenData] = useState<{
    idToken: string;
    accessToken: string;
  }>({
    idToken: '',
    accessToken: '',
  });
  const { idToken } = tokenData;

  const [localRefreshToken, setLocalRefreshToken] = useLocalStorage('harmo-web-auth-refresh-token', '');
  const [localCognitoUserName, setLocalCognitoUserName] = useLocalStorage('harmo-web-auth-cognito-username', '');

  const saveRefreshTokenAndUserName = useCallback(
    ({ refreshToken, cognitoUserName }: { refreshToken: string; cognitoUserName: string }) => {
      setLocalRefreshToken(refreshToken);
      setLocalCognitoUserName(cognitoUserName);
    },
    [setLocalCognitoUserName, setLocalRefreshToken],
  );

  useEffect(() => {
    if (urlParams.cognitoUserName && urlParams.refreshToken) {
      saveRefreshTokenAndUserName({
        refreshToken: urlParams.refreshToken,
        cognitoUserName: urlParams.cognitoUserName,
      });
    }
  }, [navigate, saveRefreshTokenAndUserName, urlParams.cognitoUserName, urlParams.refreshToken]);

  const refreshToken = urlParams.refreshToken || localRefreshToken;
  const cognitoUserName = urlParams.cognitoUserName || localCognitoUserName;

  const [isSnsNeedRedirect, setIsSnsNeedRedirect] = useLocalStorage('harmo-web-need-redirect', false);

  const { data: userInfo } = useQuery({
    queryKey: ['userInfo', user?.email, refreshToken, cognitoUserName],
    queryFn: () => fetchApi({ url: '/ps/v1/accounts/me', method: 'get' }).then((res) => res.data),
    enabled: !!user,
    staleTime: Infinity,
  });

  const { data: familyMembers } = useQuery({
    queryKey: ['fetchFamilyMembers', userInfo?.accountId],
    queryFn: () => fetchApi({ url: '/ps/v1/accounts/family', method: 'get' }).then((res) => res.data.family),
    enabled: !!userInfo?.accountId,
    staleTime: Infinity,
  });

  const { data: patients } = useQuery({
    queryKey: ['fetchSharingAccounts', userInfo?.accountId],
    queryFn: () =>
      fetchApi({ url: '/ps/v1/accounts/sharing', method: 'get' })
        .then((res) => res.data)
        .then(
          (data) =>
            ({
              [userInfo!.accountId]: {
                type: 'self',
                name: userInfo!.name,
                kana: userInfo!.kana,
                gender: userInfo!.gender,
                birthDate: userInfo!.birthDate || '',
                email: user?.email,
              },
              ...Object.fromEntries(
                familyMembers!.map((item) => [
                  item.accountId,
                  {
                    type: 'family',
                    name: item.name,
                    kana: item.kana,
                    gender: item.gender,
                    birthDate: item.birthDate || '',
                  },
                ]),
              ),
              ...Object.fromEntries(
                data.receivedAccountSharing.map((item) => [
                  item.fromAccount.accountId,
                  {
                    type: 'sharing',
                    name: item.fromAccount.name,
                    kana: item.fromAccount.kana,
                    gender: item.fromAccount.gender,
                    birthDate: item.fromAccount.birthDate || '',
                  },
                ]),
              ),
              ...Object.fromEntries(
                data.familyAccountSharing.map((item) => [
                  item.fromAccount.accountId,
                  {
                    type: 'familySharing',
                    name: item.fromAccount.name,
                    kana: item.fromAccount.kana,
                    gender: item.fromAccount.gender,
                    birthDate: item.fromAccount.birthDate || '',
                  },
                ]),
              ),
            }) satisfies Record<string, PatientInfo> as Record<string, PatientInfo>,
        ),

    enabled: !!userInfo?.accountId && !!familyMembers,
    staleTime: Infinity,
  });

  const mainPatientId = userInfo?.accountId || '';

  const [currentPatientId, updateCurrentPatientId, removeCurrentPatientId] = useLocalStorage(
    'harmo-web-current-patient-id',
    '',
  );

  const setCurrentPatientId: React.Dispatch<React.SetStateAction<string>> = useCallback(
    (value) => {
      setSearchParams((prev) => {
        prev.delete('currentAccount');
        return prev;
      });

      if (value instanceof Function) {
        updateCurrentPatientId(value(currentPatientId));
      } else {
        updateCurrentPatientId(value);
      }
    },
    [currentPatientId, setSearchParams, updateCurrentPatientId],
  );

  useEffect(() => {
    const isUrlParamsCurrentAccountValid = !!urlParams.currentAccount && !!patients?.[urlParams.currentAccount];
    if (!patients || (!!currentPatientId && !!patients?.[currentPatientId])) {
      if (isUrlParamsCurrentAccountValid && urlParams.currentAccount !== currentPatientId) {
        // NOTE: currentAccountパラメータがある場合はそのアカウントを設定
        setCurrentPatientId(urlParams.currentAccount);
        return;
      }
      // NOTE: 既にアカウントが設定されている場合は何もしない
      return;
    }
    if (isUrlParamsCurrentAccountValid) {
      // NOTE: currentAccountパラメータがある場合はそのアカウントを設定
      setCurrentPatientId(urlParams.currentAccount);
      return;
    }
    // NOTE: currentAccountパラメータがない場合はデフォルトのアカウントを設定
    const patientIds = Object.keys(patients);
    setCurrentPatientId(patientIds[0]);
  }, [currentPatientId, patients, setCurrentPatientId, setSearchParams, urlParams.currentAccount]);

  useEffect(() => {
    // NOTE: Axiosのリクエストヘッダーにトークンを設定
    if (!idToken) {
      return;
    }
    setAuthorizationHeader(idToken);
    const currentUser = jwtDecode<CognitoTokenData>(idToken);
    setUser(currentUser);
  }, [idToken]);

  const isLoading = !!idToken || !!refreshToken;

  const scopes = useMemo(() => {
    const newScopes: Scope[] = [];
    if (isLoading) {
      return newScopes;
    }

    newScopes.push('user');
    return newScopes;
  }, [isLoading]);

  const { mutate: signInWithPassword, isPending: isSignInWithPasswordPending } = useMutation({
    mutationFn: async ({ username, password }: { username: string; password: string }) => {
      // NOTE: ログイン画面からのログインの場合
      const result = await signInToCognito(username, password).catch(() => undefined);
      if (!result?.accessToken || !result?.idToken || !result?.refreshToken) {
        throw new Error('Failed to sign in');
      }
      saveRefreshTokenAndUserName({
        refreshToken: result.refreshToken,
        cognitoUserName: result.user?.getUsername() || '',
      });

      setTokenData((prev) => ({
        ...prev,
        accessToken: result.accessToken,
        idToken: result.idToken,
      }));
      return result;
    },
  });

  // NOTE: For useQuery, don't call this function directly
  const signInWithCode = useCallback(
    async (code: string) => {
      const result = await signInWithAuthorizationCode(code).catch(() => undefined);
      if (!result) {
        return false;
      }

      if (result.accessToken && result.idToken) {
        const isSignedUp = await fetchApi({
          url: '/ps/v1/accounts/me',
          method: 'get',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${result.idToken}` },
        })
          .then(() => true)
          .catch(() => false);

        if (!isSignedUp) {
          return result;
        }

        const userName = getUserNameFromToken(result.idToken);
        saveRefreshTokenAndUserName({
          refreshToken: result.refreshToken,
          cognitoUserName: userName,
        });
        setTokenData((prev) => ({
          ...prev,
          accessToken: result.accessToken,
          idToken: result.idToken,
        }));
      }
      return true;
    },
    [saveRefreshTokenAndUserName],
  );

  const signOut = useCallback(() => {
    setUser(undefined);
    removeCurrentPatientId();
    setTokenData({
      accessToken: '',
      idToken: '',
    });
    saveRefreshTokenAndUserName({
      refreshToken: '',
      cognitoUserName: '',
    });
    return true;
  }, [removeCurrentPatientId, saveRefreshTokenAndUserName]);

  const createAccount = useCallback(
    (params: { name: string; kana: string; birthDate: string; gender: Gender }) =>
      createPsAccount({
        accessToken: tokenData.accessToken,
        ...params,
      }),
    [tokenData.accessToken],
  );

  // NOTE: 自動ログイン & トークンの更新
  useQuery({
    queryKey: ['autoSignIn', refreshToken, cognitoUserName, idToken],
    queryFn: async () => {
      if (idToken) {
        const { exp } = jwtDecode<CognitoTokenData>(idToken);
        if (exp && exp * 1000 - 3 * 60 * 1000 > Date.now()) {
          // NOTE: 有効期限が残っている場合は何もしない
          return null;
        }
      }

      if (!refreshToken || !cognitoUserName) {
        throw new Error('No refresh token or cognito user name');
      }

      const result = await fetchApi({
        url: '/ps/v1/token',
        method: 'put',
        data: {
          cognitoUserName,
          refreshToken,
        },
      })
        .then((res) => res.data)
        .catch(() => undefined);

      if (!result) {
        // NOTE: トークンの更新に失敗した場合はログアウト
        navigate('/auth/sign-in');
        return false;
      }

      if (result.accessToken && result.idToken) {
        setTokenData((prev) => ({
          ...prev,
          accessToken: result.accessToken,
          idToken: result.idToken,
        }));
      }
      return result;
    },
    enabled: !!refreshToken && !!cognitoUserName,
    refetchInterval: 60 * 60 * 1000, // 1h
  });

  const value: ContextValue = useMemo(
    () => ({
      user,
      patients,
      mainPatientId,
      currentPatientId,
      setCurrentPatientId,
      isLoading,
      scopes,
      signInWithPassword,
      isSignInWithPasswordPending,
      signInWithCode,
      signInToCenterServer,
      signOut,
      createAccount,
      isSnsNeedRedirect,
      setIsSnsNeedRedirect,
    }),
    [
      user,
      patients,
      mainPatientId,
      currentPatientId,
      setCurrentPatientId,
      isLoading,
      scopes,
      signInWithPassword,
      isSignInWithPasswordPending,
      signInWithCode,
      signInToCenterServer,
      signOut,
      createAccount,
      isSnsNeedRedirect,
      setIsSnsNeedRedirect,
    ],
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}

export function AuthProtector(props: { children: React.ReactNode }) {
  const { children } = props;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const urlParams = {
    cognitoUserName: searchParams.get('cognitoUserName') || '',
    refreshToken: searchParams.get('refreshToken') || '',
  };

  const [localRefreshToken] = useLocalStorage('harmo-web-auth-refresh-token', '');
  const [localCognitoUserName] = useLocalStorage('harmo-web-auth-cognito-username', '');

  const refreshToken = urlParams.refreshToken || localRefreshToken;
  const cognitoUserName = urlParams.cognitoUserName || localCognitoUserName;

  const { user, currentPatientId } = useAuth();

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    // NOTE: 自動ログインができない場合はログイン画面にリダイレクト
    if (!refreshToken && !cognitoUserName) {
      navigate('/auth/sign-in', {
        state: {
          from: {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
            params,
          },
        },
      });
    }
  }, [cognitoUserName, location.hash, location.pathname, location.search, navigate, params, refreshToken]);

  if (!user || !currentPatientId) {
    return <Loading />;
  }
  return <>{children}</>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAuth() {
  let contextValue = useContext(context);
  if (!contextValue) {
    contextValue = DEFAULT_CONTEXT_VALUE;
    console.error('useAuth must be used within a AuthProvider');
  }
  return contextValue;
}
